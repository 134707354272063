import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const ViewProduct = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleUpdateClick = (productId) => {
    navigate(`/update-product/${productId}`);
  };

  const handleReview = (productId) => {
    navigate(`/add-review/${productId}`);
  };

  const handleViewReview = (productId) => {
    navigate(`/view-review/${productId}`);
  };

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteproduct/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Products</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Product Name</th>
            
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.category}</td>
                <td>{product.productName}</td>
              
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => handleUpdateClick(product.id)}
                  >
                    Update
                  </button>
                    <br></br>
                    <br></br>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(product.id)}
                  >
                    Delete
                  </button>

                  <button
                    className="btn btn-primary1"
                    onClick={() => handleReview(product.id)}
                  >
                    Add Review
                  </button>

                  <button
                    className="btn btn-warning"
                    onClick={() => handleViewReview(product.id)}
                  >
                    View Review
                  </button>
                  


                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewProduct;
