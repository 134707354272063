import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './App.css';

import AddCategory from './Dashboard/Addcategory';
import ViewCategory from './Dashboard/Viewcategory';
import AddProduct from './Dashboard/Addproduct';
import ViewProduct from './Dashboard/Viewproduct';
import UpdateProduct from './Dashboard/Updateproduct';
import AddBlogs from './Dashboard/Addblogs';
import ViewBlogs from './Dashboard/Viewblog';
import Addblogsmedia from './Dashboard/Addblogsmedia';
import ViewMediaBlogs from './Dashboard/Viewmediablog';
import AddCouponCode from './Dashboard/Addcoupon';
import ViewCoupon from './Dashboard/Viewcoupon';
import Neworder from './Dashboard/Neworder';
import OrderDetails from './Dashboard/Orderdetails';
import Home from './Dashboard/Home';
import Testimonial from './Dashboard/Addtestimonial';
import FAQ from './Dashboard/AddFAQ';
import Viewfaq from './Dashboard/Viewfaq';
import Viewtestimonial from './Dashboard/Viewtestimonial';
import AddReview from './Dashboard/AddReview';
import ViewReview from './Dashboard/ViewReview';



import logo from './logo515.png';


// Predefined users
const users = [
  { username: 'Nirogi', password: 'Nirogi@123' },
  { username: 'Prarit', password: 'Prarit@1995' },
  { username: 'Vansh', password: 'Vans@123$007' },
  { username: 'Admin', password: 'Nirogi@007' },
];

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const navigate = useNavigate();

  // Check if the user is already authenticated on app load
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);  // Only run once on mount  

  // Handle login
  const handleLogin = (e) => {
    e.preventDefault();

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    // Validate that both fields are filled
    if (!username || !password) {
      setErrorMessage('Please enter both username and password');
      return;
    }

    // Check if the user exists
    const user = users.find((user) => user.username === username && user.password === password);
    if (user) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      usernameRef.current.value = '';  // Clear username field
      passwordRef.current.value = '';  // Clear password field
      setErrorMessage('');
      navigate('/');
    } else {
      setErrorMessage('Invalid username or password');
    }
  };
  
  // Handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove the login state from localStorage
    navigate('/login');
  };

  // Login form component
  const LoginForm = () => (
    <div className="login-form">
      <h3>Login</h3>
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter username" 
            ref={usernameRef} // Use ref to access the input field
            required
          />
        </Form.Group>
        <Form.Group controlId="password" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            placeholder="Enter password" 
            ref={passwordRef} // Use ref to access the input field
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Login
        </Button>
        {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
      </Form>
    </div>
  );

  // If not authenticated, show login form
  if (!isAuthenticated) {
    return <LoginForm />;
  }

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img
              src={logo}
              alt="Nirogi Dhara Logo"
              style={{ height: '70px' }} // Adjust the height as needed
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link as={Link} to="/">Home</Nav.Link>

              <NavDropdown title="Category" id="categoryDropdown">
                <NavDropdown.Item as={Link} to="/add-category">Add Category</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-category">View Category</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Product" id="productDropdown">
                <NavDropdown.Item as={Link} to="/add-product">Add Product</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-product">View Product</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Coupon" id="couponDropdown">
                <NavDropdown.Item as={Link} to="/add-coupon">Add Coupon</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-coupon">View Coupon</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Order" id="orderDropdown">
                <NavDropdown.Item as={Link} to="/new-order">New Order</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Blog" id="blogDropdown">
                <NavDropdown.Item as={Link} to="/add-blog">Add ECOMM Blog</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-blog">View ECOMM Blog</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/add-media-blog">Add MEDIA Blog</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-media-blog">View MEDIA Blog</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Testimonial" id="testimonialDropdown">
                <NavDropdown.Item as={Link} to="/new-testimonial">New Testimonial</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-testimonial">Manage Testimonial</NavDropdown.Item>
              </NavDropdown>

              

              <NavDropdown title="FAQ" id="reviewDropdown">
                <NavDropdown.Item as={Link} to="/add-faq">New FAQ</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/view-faq">Manage FAQ</NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav className="ms-auto">
              <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-category" element={<AddCategory />} />
        <Route path="/view-category" element={<ViewCategory />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/view-product" element={<ViewProduct />} />
        <Route path="/update-product/:productId" element={<UpdateProduct />} />
        <Route path="/add-blog" element={<AddBlogs />} />
        <Route path="/add-media-blog" element={<Addblogsmedia />} />
        <Route path="/view-blog" element={<ViewBlogs />} />
        <Route path="/view-media-blog" element={<ViewMediaBlogs />} />
        <Route path="/add-coupon" element={<AddCouponCode />} />
        <Route path="/view-coupon" element={<ViewCoupon />} />
        <Route path="/new-order" element={<Neworder />} />
        <Route path="/orderdetails/:orderId" element={<OrderDetails />} />
        <Route path="/new-testimonial" element={<Testimonial />} />
        <Route path="/view-testimonial" element={<Viewtestimonial />} />
        <Route path="/add-faq" element={<FAQ />} />
        <Route path="/view-faq" element={<Viewfaq />} />
        <Route path='/add-review/:productId' element={<AddReview></AddReview>}/>
        <Route path='/view-review/:productId' element={<ViewReview></ViewReview>}/>
      </Routes>

      <footer className="bg-red text-center py-3 mt-auto">
        <p className="mb-0">Developed by Multitech Bulls Software Technologies Pvt Ltd</p>
      </footer>
    </>
  );
}

export default App;
