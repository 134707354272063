import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Addproduct.css';
import config from '../config';

function AddReview() {
  // State for FAQ question and answer
  const { productId } = useParams(); 
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');

  const [product, setProduct] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({
      name: '',
    
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(faqQuestion+" "+faqAnswer);
    // Prepare form data to be sent to the server
    const formData = new FormData();
    formData.append('ClientName', faqQuestion);
    formData.append('ClientReview', faqAnswer);
    formData.append('productid',productId);

   
    try {
      const response = await fetch(`${config.API_BASE_URL}/addReview`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setFaqQuestion('');
      setFaqAnswer('');

      alert('Review added successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Error adding FAQ: ' + error);
    }
  };

  useEffect(() => {
      const fetchProduct = async () => {
        try {
          const response = await fetch(`${config.API_BASE_URL}/product/${productId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setProduct(data);
  
          // Set product details
          setUpdatedProduct({
            name: data.productName,
          
          });
  
       
  
          // Load existing images and previews
         
         
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      };
  
      fetchProduct();
    }, [productId]);

  return (
    <div className="product-container">
      <div className="add-product-container">
        <h1 className="text-center mb-4">Add Review for product id:-  {productId} and Product Name: - {updatedProduct.name}</h1>
        <form onSubmit={handleSubmit}>
          {/* FAQ Question */}
          <div className="form-group mb-3">
            <label htmlFor="faqQuestion">Client Name</label>
            <input
              className="form-control mt-2"
              id="faqQuestion"
              placeholder="Enter Client Name"
              value={faqQuestion}
              onChange={(e) => setFaqQuestion(e.target.value)}
              required
              rows="3"
            />
          </div>

          {/* FAQ Answer */}
          <div className="form-group mb-3">
            <label htmlFor="faqAnswer">Client Review</label>
            <textarea
              className="form-control mt-2"
              id="faqAnswer"
              placeholder="Enter Client Review"
              value={faqAnswer}
              onChange={(e) => setFaqAnswer(e.target.value)}
              required
              rows="5"
            />
          </div>

          <button type="submit" className="btn btn-primary btn-block mt-3">
            Add Review
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddReview;
