import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';  // Import Router
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

// Get the root element
const rootElement = document.getElementById('root');

// Create a root using React 18 API
const root = ReactDOM.createRoot(rootElement);

// Use the createRoot method to render your app inside the Router
root.render(
  <Router> {/* Wrap App with Router */}
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
