import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const ViewReview = () => {

  const { productId } = useParams(); 
  const [coupon, setcoupon] = useState([]);
  const navigate = useNavigate();
   const [product, setProduct] = useState(null);
    const [updatedProduct, setUpdatedProduct] = useState({
        name: '',
      
      });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchreview/${productId}`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setcoupon(data);
        console.log("Hello "+coupon);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deletreview/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Review has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdateClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteblogs/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
        const fetchProduct = async () => {
          try {
            const response = await fetch(`${config.API_BASE_URL}/product/${productId}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProduct(data);
    
            // Set product details
            setUpdatedProduct({
              name: data.productName,
            
            });
    
         
    
            // Load existing images and previews
           
           
          } catch (error) {
            console.error('Error fetching product:', error);
          }
        };
    
        fetchProduct();
      }, [productId]);
  


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Review of Product:- {updatedProduct.name}</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Client Name</th>
              <th>Client Review</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {coupon.map(coupon=> (
              <tr key={coupon.id}>
                <td>{coupon.id}</td>
                <td>{coupon.clientname}</td>
                <td>{coupon.clientreview}</td>
              
                
                


                <td>

               
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(coupon.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewReview;
