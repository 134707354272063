import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const Viewtestimonial = () => {
  const [testimonial, settestimonial] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchtestimonials`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        settestimonial(data);
        console.log(testimonial)
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  

  

  const handleDeleteClick = async (productId) => {
    try {
        const response = await fetch(`${config.API_BASE_URL}/deletetestimonial/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Testimonial has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Testimonial</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Messsage</th>
              <th>Video Link</th>
              <th>Name</th>
              <th>Occupation</th>
            
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {testimonial.map(test=> (
              <tr key={test.id}>
                <td>{test.id}</td>
                <td>{test.testimonialMessage}</td>
                <td>{test.videoLink}</td>
                <td>{test.name}</td>
                <td>{test.occupation}</td>
               
                
                


                <td>

               

                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(test.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Viewtestimonial;
