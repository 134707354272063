import React, { useState } from 'react';
import './Addproduct.css';
import config from '../config';

function AddFAQ() {
  // State for FAQ question and answer
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(faqQuestion+" "+faqAnswer);
    // Prepare form data to be sent to the server
    const formData = new FormData();
    formData.append('faqQuestion', faqQuestion);
    formData.append('faqAnswer', faqAnswer);

    console.log(formData.faqQuestion+" "+formData.faqAnswer);
    try {
      const response = await fetch(`${config.API_BASE_URL}/addfaq`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setFaqQuestion('');
      setFaqAnswer('');

      alert('FAQ added successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Error adding FAQ: ' + error);
    }
  };

  return (
    <div className="product-container">
      <div className="add-product-container">
        <h1 className="text-center mb-4">Add FAQ</h1>
        <form onSubmit={handleSubmit}>
          {/* FAQ Question */}
          <div className="form-group mb-3">
            <label htmlFor="faqQuestion">FAQ Question</label>
            <textarea
              className="form-control mt-2"
              id="faqQuestion"
              placeholder="Enter FAQ Question"
              value={faqQuestion}
              onChange={(e) => setFaqQuestion(e.target.value)}
              required
              rows="3"
            />
          </div>

          {/* FAQ Answer */}
          <div className="form-group mb-3">
            <label htmlFor="faqAnswer">FAQ Answer</label>
            <textarea
              className="form-control mt-2"
              id="faqAnswer"
              placeholder="Enter FAQ Answer"
              value={faqAnswer}
              onChange={(e) => setFaqAnswer(e.target.value)}
              required
              rows="5"
            />
          </div>

          <button type="submit" className="btn btn-primary btn-block mt-3">
            Save FAQ
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddFAQ;
