import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const Viewfaq = () => {
  const [coupon, setcoupon] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchfaq`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setcoupon(data);
        console.log("Hello "+coupon);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deletefaq/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("FAQ has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdateClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteblogs/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View FAQ</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>FAQ QUESTION</th>
              <th>FAQ ANSWER</th>
              
            </tr>
          </thead>
          <tbody>
            {coupon.map(coupon=> (
              <tr key={coupon.id}>
                <td>{coupon.faqQuestion}</td>
                <td>{coupon.faqAnswer}</td>
              
                
                


                <td>

               
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(coupon.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Viewfaq;
